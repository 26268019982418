.testimonial {
  .tiles-item-inner {
    background: get-color(dark, 2);
  }
}

.testimonial-item-content {
  position: relative;
}

.testimonial-title {
  content: "";
  display: block;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 16px;
  background-repeat: no-repeat;
}

.testimonial-text {
  color: get-color(light, 2);
}

.testimonial-item-footer {
  font-weight: 600;
  padding-top: 20px;

  &.has-top-divider {
    &::before {
      background: get-color(dark, 3);
    }
  }
}

.testimonial-item-link {
  color: get-color(secondary, 3);

  a {
    color: get-color(secondary, 3);
    text-decoration: none;

    &:hover {
      color: get-color(secondary, 2);
    }
  }
}
